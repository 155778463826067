/**
 * @summary A polyfill to stub out
 * {@link https://bit.ly/EmeMar15 EME draft 12 March 2015} on browsers without
 * EME.
 * All methods will fail.
 */
const PatchedMediaKeysNop = class {
  /**
   * Installs the polyfill if needed.
   */
  static install() {
    if (
      !window.HTMLVideoElement ||
      (navigator.requestMediaKeySystemAccess && MediaKeySystemAccess.prototype.getConfiguration)
    ) {
      return;
    }

    console.warn('EME not available.');

    // Alias.
    // const PatchedMediaKeysNop = shaka.polyfill.PatchedMediaKeysNop;

    // Install patches.
    navigator.requestMediaKeySystemAccess = PatchedMediaKeysNop.requestMediaKeySystemAccess;
    // Delete mediaKeys to work around strict mode compatibility issues.

    delete HTMLMediaElement.prototype.mediaKeys;
    // Work around read-only declaration for mediaKeys by using a string.

    HTMLMediaElement.prototype.mediaKeys = null;

    HTMLMediaElement.prototype.setMediaKeys = PatchedMediaKeysNop.setMediaKeys;
    // These are not usable, but allow Player.isBrowserSupported to pass.
    window.MediaKeys = PatchedMediaKeysNop.MediaKeys;
    window.MediaKeySystemAccess = PatchedMediaKeysNop.MediaKeySystemAccess;
  }

  /**
   * An implementation of navigator.requestMediaKeySystemAccess.
   * Retrieves a MediaKeySystemAccess object.
   *
   * @this {!Navigator}
   * @param {string} keySystem
   * @param {!Array.<!MediaKeySystemConfiguration>} supportedConfigurations
   * @return {!Promise.<!MediaKeySystemAccess>}
   */
  // eslint-disable-next-line no-unused-vars
  static requestMediaKeySystemAccess(keySystem, supportedConfigurations) {
    console.warn('PatchedMediaKeysNop.requestMediaKeySystemAccess');

    return Promise.reject(new Error('The key system specified is not supported.'));
  }

  /**
   * An implementation of HTMLMediaElement.prototype.setMediaKeys.
   * Attaches a MediaKeys object to the media element.
   *
   * @this {!HTMLMediaElement}
   * @param {MediaKeys} mediaKeys
   * @return {!Promise}
   */
  static setMediaKeys(mediaKeys) {
    console.warn('PatchedMediaKeysNop.setMediaKeys');

    if (mediaKeys == null) {
      return Promise.resolve();
    }

    return Promise.reject(new Error('MediaKeys not supported.'));
  }
};

/**
 * An unusable constructor for MediaKeys.
 * @implements {MediaKeys}
 */
PatchedMediaKeysNop.MediaKeys = class {
  constructor() {
    throw new TypeError('Illegal constructor.');
  }

  /** @override */
  createSession() {}

  /** @override */
  setServerCertificate() {}
};

/**
 * An unusable constructor for MediaKeySystemAccess.
 * @implements {MediaKeySystemAccess}
 */
PatchedMediaKeysNop.MediaKeySystemAccess = class {
  constructor() {
    /** @override */
    this.keySystem = ''; // For the compiler.

    throw new TypeError('Illegal constructor.');
  }

  /** @override */
  getConfiguration() {}

  /** @override */
  createMediaKeys() {}
};

PatchedMediaKeysNop.install();
